.main {
    padding: 10vh 0;
    background-image: url('../../assets/images/404.jpg');
    flex-grow: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 20%;
    @media (max-width: 1200px) {
        padding: 8vh 0;
    }
    @media (max-width: 768px) {
        padding: 15vh 0;
        background-position: 60% 20%;
    }
    @media (max-width: 768px) {
        padding: 20vh 0;
    }
    @media (max-width: 480px) {
        background-position: 60% 50%;
    }
    @media (max-width: 480px) {
        padding: 16vh 0;
    }
    @media (max-width: 480px) {
        padding: 15vh 0;
    }
}

.main__err {
    padding: 15vh 0;
}

.main_404 {
    text-align: center;
    font-weight: 700;
    font-size: 12.5rem;
    line-height: 100%;
    letter-spacing: 0.015em;
    padding-bottom: 0;
    @media (max-width: 1366px) {
        font-size: 10rem;
    }
    @media (max-width: 480px) {
        font-size: 8rem;
    }
    @media (max-width: 376px) {
        font-size: 7rem;
    }
}

.main_sub {
    text-align: center;
    @media (max-width: 480px) {
        font-size: 2.5rem;
    }
    @media (max-width: 376px) {
        font-size: 2rem;
    }
}

.block {
    padding: 2.5rem;
    background: #FFFFFF;
    border-radius: 15px;
    margin-bottom: 1.875rem;
    @media (max-width: 576px) {
        padding: 1.875rem;
    }
    @media (max-width: 414px) {
        padding: 1.5625rem;
    }
    @media (max-width: 376px) {
        padding: 1.25rem;
        border-radius: 0.625rem;
    }
}

.text {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 137.5%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #767783;
    padding-bottom: 1.6875rem;
    @media (max-width: 414px) {
        font-size: 0.625rem;
        padding-bottom: 1.25rem;
    }
}

.button {
    margin: 0 auto;
}

.logo {
    margin: 0 auto;
    display: block;
    height: 70px;
    width: 70px;
    border-radius: 15px;
    @media (max-width: 376px) {
        height: 60px;
        width: 60px;
        border-radius: 10px;
    }
  }