@import url('./fonts.scss');
@import url('./_vars.scss');
@import url('./_libs.scss');
@import url('./_reset.scss');


body {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    font-family: 'Gilroy', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #000000;
    overflow-x: hidden;
    @media (max-width: 414px) {
        font-size: 12px;
    }
}

.scroll-fix {
    position: absolute;
    width: calc(100vw - 100%);
}

.wrapper {
    max-width: 1280px;
    margin: 0 auto;
    @media (max-width: 1366px) {
        max-width: 100%;
        margin: 0 30px;
    }
    @media (max-width: 576px) {
        margin: 0 20px;
    }
    @media (max-width: 414px) {
        margin: 0 15px;
    }
}

.component__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-family: 'Gilroy', 'sans-serif';
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    border: none;
    border: 2px solid #070D3F;
    background: #070D3F;
    border-radius: 15px;
    color: #FFFFFF;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        color: #010203;
        background: #FFFFFF;
        border: 2px solid #010203;
        border-radius: 15px;
    }
    @media (max-width: 414px) {
        font-size: 12px;
    }
    &-submit {
        background: #00AB30;
        border: 2px solid #00AB30;
        box-shadow: 0px 10px 65px rgba(0, 171, 48, 0.2);
        &:hover {
            color: #FFFFFF;
            background: #00AB30;
            border: 2px solid #00AB30;
            transform: translateY(3px);
        }
    }
    &-cancel {
        background: #EB001B;
        border: 2px solid #EB001B;
        &:hover {
            color: #FFFFFF;
            background: #EB001B;
            border: 2px solid #EB001B;
            transform: translateY(3px);
        }
    }
    &-big {
        padding: 23px 35px;
        @media (max-width: 1366px) {
            padding: 20px 30px;
        }
        @media (max-width: 414px) {
            padding: 15px 20px;
        }
    }
    &-small {
        padding: 15px 28px;
        @media (max-width: 1366px) {
            padding: 15px 25px;
        }
        @media (max-width: 414px) {
            padding: 8.5px 14.5px;
            border-radius: 12px;
        }
    }
}

.component__title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 100%;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    padding-bottom: 30px;
    @media (max-width: 1366px) {
        font-size: 50px;
        padding-bottom: 25px;
    }
    @media (max-width: 414px) {
        font-size: 40px;
        padding-bottom: 25px;
    }
    @media (max-width: 375px) {
        font-size: 32px;
        padding-bottom: 20px;
    }
}

// .component__inp {
//     display: block;
//     background: #F5F6FA;
//     border-radius: 8px;
//     border: 1px solid #F5F6FA;
//     font-weight: 600;
//     font-size: 16px;
//     line-height: 124.5%;
//     padding: 15px;
//     height: 50px;
//     width: 100%;
//     color: #151940;
//     cursor: pointer;
//     transition: all 0.3s ease;
//     &:active, 
//     &:hover, 
//     &:focus {
//         outline: 0;
//         outline-offset: 0;
//     }
//     &:hover {
//         background: rgba(49, 75, 206, 0.08);
//         border: 1px solid #314BCE;
//         color: #314BCE;
//         &::placeholder {
//             color: #314BCE;
//         }
//     }
//     &::placeholder {
//         font-size: 16px;
//         line-height: 124.5%;
//         color: #767783;;
//     }
//     @media (max-width: 414px) {
//         font-size: 12px;
//         padding: 12px;
//         height: 42px;
//         &::placeholder {
//             font-size: 12px;
//         }
//     }
//     &-err {
//         background: rgba(206, 49, 49, 0.08);
//         border: 1px solid #EB001B;
//         color: #EB001B;
//         &:active, 
//         &:hover, 
//         &:focus {
//             outline: 0;
//             outline-offset: 0;
//         }
//         &::placeholder {
//             color: #EB001B;
//         }
//         &:hover {
//             background: rgba(206, 49, 49, 0.08);
//             border: 1px solid #EB001B;
//             color: #EB001B;
//             &::placeholder {
//                 color: #EB001B;
//             }
//         }
//     }
//     &-success {
//         background: rgba(49, 75, 206, 0.08);
//         border: 1px solid #314BCE;
//         color: #314BCE;
//         &::placeholder {
//             color: #314BCE;
//         }
//         &:active, 
//         &:hover, 
//         &:focus {
//             outline: 0;
//             outline-offset: 0;
//         }
//     }
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.component__subt {
    text-align: left;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #767783;
    margin-bottom: 12px;
    span {
        font-weight: 700;
    }
    @media (max-width: 414px) {
        font-size: 10px;
        margin-bottom: 8px;
    }
}

.container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column; 
}

.main {
    flex-grow: 1;
}

.wrapper__form,
.wrapper__error {
    max-width: 580px;
    @media (max-width: 1366px) {
        margin: 0 auto;
        max-width: 580px;
    }

    @media (max-width: 768px) {
        max-width: 85vw;
    }

    @media (max-width: 480px) {
        padding: 0px 20px;
        max-width: 100%;
    }
}

.component__union {
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
    border-bottom: 2px dashed #EEEDF0;
    @media (max-width: 1024px) {
        padding: 60px 0;
    }
    @media (max-width: 820px) {
        padding: 50px 0;
        flex-direction: column;
    }
    @media (max-width: 480px) {
        padding: 40px 0;
    }
    @media (max-width: 414px) {
        padding: 30px 0;
    }
}

.component__union-type {
    max-width: 420px;
    @media (max-width: 1024px) {
        max-width: auto;
        width: 33%;
    }
    @media (max-width: 820px) {
        width: 100%;
        margin-bottom: 30px;
    }
    @media (max-width: 480px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.component__union-title {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.015em;
    color: #010203;
    padding-bottom: 20px;
    @media (max-width: 480px) {
        padding-bottom: 12px;
    }
}


.component__union-descr {
    color: #767783;
    @media (max-width: 414px) {
        font-size: 12px;
    }
}

.component__block {
    background: #FFFFFF;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08), 0px 0.893452px 4.91399px rgba(0, 0, 0, 0.0476886), 0px 0.266004px 1.46302px rgba(0, 0, 0, 0.0323114);
    border-radius: 15px;
    &-main {
        padding: 30px 30px 40px;
        @media (max-width: 480px) {
            padding: 30px 25px;
        }
        @media (max-width: 414px) {
            padding: 20px;
        }
    }
    &-submit {
        display: flex;
        justify-content: flex-end;
        background: #F8F8FA;
        padding: 15px 30px;
        @media (max-width: 480px) {
            padding: 15px 25px;
        }
        @media (max-width: 414px) {
            padding: 15px 20px;
        }
    }
    &-delete {
        padding: 15px 30px;
        button {
            background: #EB001B;
            border-radius: 15px;
            border: 2px solid #EB001B;
            &:hover {
                color: #EB001B;
                background: transparent;
                border: 2px solid #EB001B;
            }
        }
        @media (max-width: 480px) {
            padding: 0 25px 15px;
        }
        @media (max-width: 414px) {
            padding: 0 20px 15px;
        }
    }
    &-send {
        padding: 15px 30px;
        button {
            background: #00AB30;
            border-radius: 15px;
            border: 2px solid #00AB30;
            &:hover {
                color: #00AB30;
                background: transparent;
                border: 2px solid #00AB30;
            }
        }
        @media (max-width: 480px) {
            padding: 0 25px 15px;
        }
        @media (max-width: 414px) {
            padding: 0 20px 15px;
        }
    }
    &-union {
        max-width: 500px;
        @media (max-width: 1024px) {
            max-width: 100%;
        }
    }
    &-title {
        font-weight: 700;
        font-size: 14px;
        padding-bottom: 30px;
        @media (max-width: 480px) {
            padding-bottom: 12px;
        }
    }
}


