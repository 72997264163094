@import '../../styles/vars';

.panel__wrapper {
    padding: 35px 40px;
    background: #019EF7;
    border-radius: 6px;
    transform: translateY(-50%);
    @media (max-width: 1024px) {
        padding: 30px 30px 10px;
    }
    @media (max-width: 414px) {
        transform: translateY(-25%);
    }
}

.blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
}

.block {
    width: 22%;
    color: #FFFFFF;
    height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1024px) {
        width: 47.5%;
        height: 56px;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
 
            .block__logo {
                align-self: start;
            
        }
    }
    @media (max-width: 414px) {
        width: 100%;
        height: 50px;
        margin-bottom: 25px;
    }
}

.block__top {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    @media (max-width: 768px) {
        padding-bottom: 0;
    }
}

.block__logo {
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
}

.block__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.015em;
}

.block__status {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.015em;
    display: flex;
    align-items: center;
    svg {
        display: block;
        margin-left: 12px;
        path {
        stroke: #FFFFFF;
        }
    }

    @media (max-width: 1200px) {
        font-size: 26px;
    }
    @media (max-width: 768px) {
        font-size: 24px;
    }
    @media (max-width: 480px) {
        font-size: 22px;
    }
}