.new__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.container {
    background: transparent;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.block__main {
    button {
        color: #010203;
        border: 1px solid #EEEDF0;
        background: transparent;
        &:hover {
            border: 1px solid #010203;
        }
    }
    h5 {
        padding-bottom: 60px;
    }
    @media (max-width: 1200px) {
        h5 {
            padding-bottom: 40px;
        }
    }
    @media (max-width: 414px) {
        h5 {
            padding-bottom: 30px;
            font-size: 1rem;
        }
    }
}

.block__wrapper {
    position: relative;
    max-width: 500px;
}

.block {
    width: 59%;
    label {
        align-items: flex-start;
    }
    legend {
        font-weight: 600;
        color: #010203;
        span {
        font-weight: normal;
        display: block;
        padding-top: 10px;
        }
    }
    .form__block + .form__block {
        margin-top: 30px;
    }
    @media (max-width: 820px) {
        width: 100%;
    }
    @media (max-width: 576px) {
        .form__block + .form__block {
            margin-top: 20px;
        }
    }

}

.sub__union {
    width: 59%;
    .block {
        width: 100%;
    }
    .block:first-child {
        .block__main {
            padding-bottom: 70px;
        }
    }
    .block + .block {
        margin-top: -30px;
    }
    @media (max-width: 820px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        .block:first-child {
            .block__main {
                padding-bottom: 60px;
            }
        }
    }
}

.title {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #010203;
    margin-bottom: 30px;
}

.btn__block {
    display: flex;
    margin-top: 40px;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
    @media (max-width: 576px) {
        margin-top: 20px;
    }
    button {
        width: 325px;
        @media (max-width: 414px) {
            width: 100%;
        }
        &:first-child {
            margin-right: 20px;
            @media (max-width: 1200px) {
                margin-right: 0;
                margin-bottom: 15px;
            }
            @media (max-width: 820px) {
                margin-right: 20px;
                margin-bottom: 0;
            }
            @media (max-width: 768px) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}

.project__union {
    padding-top: 0px;
}

.submit__block {
    button + button {
        margin-left: 30px;
    }

    button + a{
        margin-left: 30px;
    }
}

.submit__main {
    background: #F8F8FA;
}