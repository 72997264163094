.block__main {
    button {
        color: #010203;
        border: 1px solid #EEEDF0;
        background: transparent;
        &:hover {
            border: 1px solid #010203;
        }
    }
    h5 {
        padding-bottom: 60px;
    }
    @media (max-width: 1200px) {
        h5 {
            padding-bottom: 40px;
        }
    }
    @media (max-width: 414px) {
        h5 {
            padding-bottom: 30px;
            font-size: 1rem;
        }
    }
}

.block__wrapper {
    position: relative;
    max-width: 500px;
}

.block {
    width: 59%;
    label {
        align-items: flex-start;
    }
    legend {
        font-weight: 600;
        color: #010203;
        span {
        font-weight: normal;
        display: block;
        padding-top: 10px;
        }
    }
    .form__block + .form__block {
        margin-top: 30px;
    }
    @media (max-width: 820px) {
        width: 100%;
    }
    @media (max-width: 576px) {
        .form__block + .form__block {
            margin-top: 20px;
        }
    }

}