@import '../../styles/vars';

.new__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.container {
    background: transparent;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.verification {
    background: #00AB30;
    border-radius: 6px;
    padding: 35px 40px;
    margin-top: 50px;
    color: #FFFFFF;
    @media (max-width: 1024px) {
        margin-top: 30px;
        padding: 30px;
    }
    @media (max-width: 480px) {
        padding: 30px 25px;
    }
    @media (max-width: 414px) {
        padding: 20px;
    }
}

.verification__descr {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.015em;
}

.verification__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.015em;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
        font-size: 1.625rem;
        margin-bottom: 15px;
    }
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    @media (max-width: 480px) {
        font-size: 1.375rem;
    }
}

.verification__denied {
    background: #EB001B;
}

.panel__wrapper {
    padding: 35px 40px;
    background: #019EF7;
    border-radius: 6px;
    transform: translateY(-50%);
    min-height: 136px;
    @media (max-width: 1024px) {
        padding: 30px 30px 10px;
    }
    @media (max-width: 414px) {
        transform: translateY(-25%);
    }
}

.panels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
}

.panel {
    width: 22%;
    color: #FFFFFF;
    height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1024px) {
        width: 47.5%;
        height: 56px;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        &:nth-child(4n+2) {
            .block__logo {
                align-self: start;
            }
        }
    }
    @media (max-width: 414px) {
        width: 100%;
        height: 50px;
        margin-bottom: 25px;
    }
}

.panel__top {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    @media (max-width: 768px) {
        padding-bottom: 0;
    }
}

.panel__logo {
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
}

.panel__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.015em;
}

.panel__status {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.015em;
    display: flex;
    align-items: center;
    svg {
        display: block;
        margin-left: 12px;
        path {
        stroke: #FFFFFF;
        }
    }

    @media (max-width: 1200px) {
        font-size: 26px;
    }
    @media (max-width: 768px) {
        font-size: 24px;
    }
    @media (max-width: 480px) {
        font-size: 22px;
    }
}

.block__img {
    width: 80px;
    height: 80px;
    background: #EDF1F6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.06em;
    color: #5077FF;
    margin-bottom: 20px;
}

.block__main {
    button {
        color: #010203;
        border: 1px solid #EEEDF0;
        background: transparent;
        &:hover {
            border: 1px solid #010203;
        }
    }
}

.block__wrapper {
    position: relative;
    max-width: 500px;
}



.block {
    width: 59%;
    @media (max-width: 820px) {
        width: 100%;
    }
    label {
        align-items: flex-start;
    }
    legend {
        font-weight: 600;
        color: #010203;
        span {
        font-weight: normal;
        display: block;
        padding-top: 10px;
        }
    }

    .form__block + .form__block {
        margin-top: 30px;
        @media (max-width: 576px) {
            margin-top: 20px;
        }
    }
}

.sub__union {
    width: 59%;
    @media (max-width: 820px) {
        width: 100%;
    }
    .block {
        width: 100%;
    }

    .block:first-child {
        .block__main {
            padding-bottom: 70px;
            @media (max-width: 480px) {
                padding-bottom: 60px;
            }
        }
    }

    .block + .block {
        margin-top: -30px;
    }
}

.image {
    width: 140px;
    height: 80px;
    background: #EDF1F6;
    border-radius: 8px;
    margin-bottom: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image__add {
    background-size: 30%;
}

.title {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #010203;
    margin-bottom: 30px;
}

.btn__block {
    display: flex;
    margin-top: 40px;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
    @media (max-width: 576px) {
        margin-top: 20px;
    }
    button {
        width: 325px;
        @media (max-width: 414px) {
            width: 100%;
        }
        &:first-child {
            margin-right: 20px;
            @media (max-width: 1200px) {
                margin-right: 0;
                margin-bottom: 15px;
            }
            @media (max-width: 820px) {
                margin-right: 20px;
                margin-bottom: 0;
            }
            @media (max-width: 768px) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}

.project__union {
    padding-top: 0px;
}

.submit__block {
    button + button {
        margin-left: 30px;
    }

    button + a{
        margin-left: 30px;
    }
}

.submit__main {
    background: #F8F8FA;
}

.date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
    button:last-child{
        border: 2px solid #070D3F;
        background: #070D3F;
        color: #FFFFFF;
        margin-left: 30px;
        &:hover {
            color: #010203;
            background: #FFFFFF;
            border: 2px solid #010203;
        }
    }
    @media (max-width: 1024px) {
        flex-wrap: wrap;
        align-items: flex-start;
        button:last-child {
            margin-left: auto;
        }
        input {
            margin-bottom: 20px;
        }
    }
    @media (max-width: 820px) {
        align-items: center;
        input {
            margin-bottom: 0px;
        }
    }
}
