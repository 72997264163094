.wrapper {
    position:  relative;
    width: 100%;
}
.label {
    display: flex;
    align-items: center;
    cursor: pointer;
}
  
.label__block {
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 2px solid #EEEDF0;
    margin-right: 15px;
    @media (max-width: 414px) {
        margin-right: 10px;
    }
}
  
.label__block::after {
    content: "";
    visibility: hidden;
    background-image: url('../../assets/images/check.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: showBlock 0.3s ease;
}
  
.checkbox:checked + .label .label__block::after {
    visibility: visible;
}
  
.checkbox:checked + .label .label__block {
    border: 2px solid #019EF7;
    background: #019EF7;
    transition: all 0.3s ease;
}
  
.checkbox {
    position: absolute;
    width: 0.01px;
    height: 0.01px;
    top: 0;
    left: 0;
    visibility: hidden;
}
  
.legend {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #767783;
    display: inline-block;
    a {
        color: #5077FF;
        text-decoration: underline;
    }
    @media (max-width: 414px) {
        font-size: 12px;
    }
}