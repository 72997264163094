@import '../../styles/vars';


.top {
    padding: 34px 0px 130px;
    background-image: url('../../assets/images/profile_bg.jpg');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
}
  
.top__wrapper {
    background: transparent;
}

.navigation {
    display: flex;
    align-items: center;
}

.navigation__block {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.015em;
    color: #020211;
    padding: 0px 10px;
    cursor: pointer;
    transition: $anim;
    &:first-child {
        padding-left: 0;
    }
    img {
        display: inline-block;
        flex-shrink: 0;
        margin-right: 12px;
    }
    @media (max-width: 576px) {
        font-size: 12px;
    }
    @media (max-width: 480px) {
        font-size: 10px;
        padding: 0px 8px;
    }
}

.navigation__block + .navigation__block {
    position: relative;
    &::after {
        content: '';
        height: 11px;
        width: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(50%, -50%);
        background: #767783;
    }
}

//

.top__info {
    padding-top: 40px;
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
        max-width: 100%;
    }
} 
  
.info__main {
    display: flex;
    flex-direction: column;
}
  
.info__img {
    display: block;
    width: 85px;
    height: 85px;
    border-radius: 6px;
    flex-shrink: 0;
    margin-right: 25px;
    @media (max-width: 1024px) {
        width: 75px;
        height: 75px;
    }
    @media (max-width: 576px) {
        width: 65px;
        height: 65px;
        margin-right: 20px;
    }
}

.info__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 0.015em;
    color: #010203;
    padding-bottom: 10px;
    @media (max-width: 1024px) {
        font-size: 32px;
    }
    @media (max-width: 576px) {
        font-size: 24px;
    }
}

.info__settings {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: #FFFFFF;
    transition: $anim;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #000000;
    svg {
        display: inline-block;
        flex-shrink: 0;
        margin-left: 10px;
    }
    @media (max-width: 414px) {
        width: fit-content;
        span {
            display: none;
        }
        svg {
            margin-left: 0;
        }
    }
}
