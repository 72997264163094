.inp {
    display: block;
    background: #F5F6FA;
    border-radius: 8px;
    border: 1px solid #F5F6FA;
    font-weight: 600;
    font-size: 16px;
    line-height: 124.5%;
    padding: 15px;
    height: 50px;
    width: 100%;
    color: #151940;
    cursor: pointer;
    transition: all 0.3s ease;
    &:active, 
    &:hover, 
    &:focus {
        outline: 0;
        outline-offset: 0;
    }
    &:hover {
        background: rgba(49, 75, 206, 0.08);
        border: 1px solid #314BCE;
        color: #314BCE;
        &::placeholder {
            color: #314BCE;
        }
    }
    &::placeholder {
        font-size: 16px;
        line-height: 124.5%;
        color: #767783;;
    }
    @media (max-width: 414px) {
        font-size: 12px;
        padding: 12px;
        height: 42px;
        &::placeholder {
            font-size: 12px;
        }
    }
    &__err {
        background: rgba(206, 49, 49, 0.08);
        border: 1px solid #EB001B;
        color: #EB001B;
        &:active, 
        &:hover, 
        &:focus {
            outline: 0;
            outline-offset: 0;
        }
        &::placeholder {
            color: #EB001B;
        }
        &:hover {
            background: rgba(206, 49, 49, 0.08);
            border: 1px solid #EB001B;
            color: #EB001B;
            &::placeholder {
                color: #EB001B;
            }
        }
    }
    &__success {
        background: rgba(49, 75, 206, 0.08);
        border: 1px solid #314BCE;
        color: #314BCE;
        &::placeholder {
            color: #314BCE;
        }
        &:active, 
        &:hover, 
        &:focus {
            outline: 0;
            outline-offset: 0;
        }
    }
}