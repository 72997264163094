.range {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: #F5F6FA;
    border-radius: 6px;
    background-image: linear-gradient(#019EF7, #019EF7);
    // background-size: 70% 100%;
    background-clip: border-box;
    background-repeat: no-repeat;
  }

  .range:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  
  /* Input Thumb */
  .range::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #019EF7;
    border: 2px solid #fff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #fff;
    transition: background .3s ease-in-out;
  }
  
  .range::-moz-range-thumb {
    appearance: none;
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #019EF7;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #fff;
    transition: background .3s ease-in-out;
  }
  
  .range::-ms-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #019EF7;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #fff;
    transition: background .3s ease-in-out;
  }
  
  .range::-webkit-slider-thumb:hover {
    background: #019EF7;
  }
  
  .range::-moz-range-thumb:hover {
    background: #019EF7;
  }
  
  .range::-ms-thumb:hover {
    background: #019EF7;
  }
  
  /* Input Track */
  .range::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  
  .range::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  .range::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  .range::-ms-fill-lower {
    background: #019EF7;
    border: 0.2px solid #010101;
    border-radius: 6px;
  }
  .range:focus::-ms-fill-lower {
    background: #019EF7;
  }
  .range::-ms-fill-upper {
    background: #F5F6FA;
    border: 0.2px solid #F5F6FA;
    border-radius: 6px;
  }
  .range:focus::-ms-fill-upper {
    background: #019EF7;
  }
  
  
.gradation__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.gradation {
    text-align: center;
    width: 20px;
}