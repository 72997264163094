@import '../../styles/vars';


.nav {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12%;
    left: 240px;
    @media (max-width: 1200px) {
      width: 15%;
    }
    @media (max-width: 1024px) {
      left: 200px;
      width: 20%;
    }
    @media (max-width: 768px) {
      width: 25%;
      left: 70px;
    }
    @media (max-width: 576px) {
      justify-content: start;
      left: 50%;
      transform: translateX(-50%);
      background: #FFFFFF;
      width: 100vw;
      opacity: 0;
      position: absolute;
      height: 60px;
      padding: 0px 12vw;
      z-index: 3;
      transition: $anim;
    }
    @media (max-width: 414px) {
      padding: 0px 8vw;
    }
}
  
.nav__item {
  font-weight: 700;
  transition: $anim;
  cursor: pointer;
  color: #000000;
  &:hover {
    color: #5077FF;
  }
}

@media (max-width: 576px) {
  .nav__item + .nav__item {
    margin-left: 50px;
  }
}
  
.active {
  color: #5077FF;
}