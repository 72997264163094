@import '../../styles/vars';

.header {
    background: #FFFFFF;
    flex-shrink: 0;
  }
  
  .header__wrapper {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 8;
    background: #FFFFFF;
    opacity: 1;
    @media (max-width: 1024px) {
      height: 70px;
    }
    // @media (max-width: 768px) {
    //   height: 60px;
    // }
    @media (max-width: 414px) {
      height: 50px;
    }
  }

  .header__left,
  .header__right 
  {
    max-width: fit-content;
    flex-shrink: 0;
    z-index: 7;
    background: #FFF;
  }

  .header__left {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
    }
  }
  
  .header__center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-grow: 1;
    width: auto;
    padding: 0 4vw;
    z-index: 7;
    background: #FFF;
  }
  
  .logo {
    display: flex;
    align-items: center;
  
    &:hover {
      transition: $anim;
    }
  }
  
  .logo__img {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 15px;
    @media (max-width: 768px) {
      margin-right: 0;
    }
    @media (max-width: 576px) {
      margin-left: 6vw;
    }
    @media (max-width: 414px) {
      width: 36px;
      margin-left: 8vw;
    }
  }
  
  .logo__text {
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    max-width: 136px;
    color: #000000;
    @media (max-width: 1200px) {
      font-size: 16px;
      max-width: 120px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      max-width: 105px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  

.user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  flex-shrink: 0;
}
  
  
.header__search{
  border-left: 1px solid #EEEDF0;
  width: 50px;
  height: 80px;
  position: absolute;
  margin-left: auto;
  top: 50%;
  right: 4vw;
  transform: translateY(-50%);
  z-index: 10;
  transition: all 500ms cubic-bezier(.21,.8,.37,.97);
  @media (max-width: 1024px) {
    height: 70px;
    flex-grow: 1;
  }
  @media (max-width: 768px) {
    height: 60px;
  }
  @media (max-width: 576px) {
    height: 50px;
  }
}
  
.search__open {
  width: calc(100% - 6vw);
}

.search__inp {
  height: 80px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  outline: none;
  transition: all .5s ease-in-out;
  padding-right: 40px;
  color:#767783;

  @media (max-width: 1024px) {
    width: 35px;
    height: 70px;
  }
  @media (max-width: 768px) {
    width: 20px;
    height: 60px;
    font-size: 12px;
    &::placeholder {
        font-size: 12px;
    }
  }
  @media (max-width: 576px) {
    width: 20px;
    height: 50px;
  }
}
  
.search__inp::placeholder{
  color:#767783;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  border-left: 1px solid transparent;
}

.search__btn{
  padding: 25px;
  width: 50px;
  height: 80px;
  border-style: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0px;
  background-color:transparent;
  pointer-events: painted;  
  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #767783;
    transition: $anim;
  }
  &:hover {
    svg {
      fill: #5077FF;
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  @media (max-width: 1024px) {
    width: 35px;
    height: 70px;
  }
  @media (max-width: 768px) {
    width: 20px;
    height: 60px;
  }
  @media (max-width: 576px) {
    width: 20px;
    height: 50px;
     svg {
        width: 16px;
        height: 16px;
    }
  }
}

.search__open .search__inp {
  width: 100%;
  background-color: #FFFFFF;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(.21,.8,.37,.97);
}

.search__open .search__inp{
  width: 100%;
  background-color: #FFFFFF;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(.21,.8,.37,.97);
}
  
.user__signup {
  margin-right: 35px;
  @media (max-width: 1024px) {
    margin-right: 20px;
  }
  @media (max-width: 576px) {
    margin-right: 3.5vw;
  }
}

.user__signin {
  display: block;
  transition: $anim;
  cursor: pointer;
}
  
.user__name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  padding-right: 30px;
  @media (max-width: 768px) {
    display: none;
  }
}

.user__img {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
  }
}
  
.account {
  cursor: pointer;
  transition: $anim;
}


.acсount__full {
  display: block;
  position: absolute;
  width: 170px;
  top: 90px;
  right: 0;
  padding: 25px 39px 25px 20px;
  background: #FFFFFF;
  box-shadow: 
            0px 4px 8px rgb(0 0 0 / 7%), 
            0px 4px 8px rgb(0 0 0 / 5%), 
            0px 4px 8px rgb(0 0 0 / 4%), 
            0px 4px 8px rgb(0 0 0 / 4%), 
            0px 4px 8px rgb(0 0 0 / 3%), 
            0px 4px 8px rgb(0 0 0 / 2%);
  border-radius: 6px;
  transition: all 0.3s ease;
  z-index: 10;
  animation: showBlock 0.3s ease forwards;
}
  
.account__menu {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #010203;
  a {
    display: block;
    color: #000000;
    transition: all 0.3s ease;
    padding-bottom: 8px;
    border-bottom: 4px solid transparent;
    &:hover {
      border-bottom: 4px solid #5077FF;
    }
  }
  a + a {
    margin-top: 20px;
  }
}
  
.account__logout {
  padding-top: 25px;
  border-bottom: 4px solid transparent;
  padding-bottom: 8px;
  transition: all 0.3s ease;
  &:hover {
    border-bottom: 4px solid #5077FF;
  }
  svg {
    display: inline-block;
    margin-right: 10px;
    vertical-align: bottom;
  }
  a {
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #000000;
      vertical-align: bottom;
  }
}

@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  
