//DatePicker

.react-datepicker-wrapper {
    // flex-grow: 1;
    width: 332px !important;
}

.react-datepicker-wrapper input {
    display: block;
    background: #F5F6FA;
    border-radius: 8px;
    border: 1px solid #F5F6FA;
    font-weight: 600;
    font-size: 16px;
    line-height: 124.5%;
    padding: 15px;
    height: 50px;
    width: 332px !important;
    color: #151940;
    cursor: pointer;
    transition: all 0.3s ease;
    &:active, 
    &:hover, 
    &:focus {
        outline: 0;
        outline-offset: 0;
    }
}

.react-datepicker {
    background: #FFFFFF;
    border-radius: 15px !important;
    border: 1px solid #aeaeae;
    font-family: "Gilroy", "sans-serif" !important;
    border-radius: 15px;
    border-bottom: 1px solid #aeaeae;
}

.react-datepicker__header {
    border-radius: 15px 15px 0 0 !important;
    // border: 1px solid #aeaeae;
    background: #FFFFFF !important;
    border-bottom: none !important;
    padding: 30px 20px 20px 20px !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    text-transform: capitalize;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    line-height: 2rem !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    font-size: 1rem !important;
    line-height: 1.75rem !important;
    margin: 0 !important;
}

.react-datepicker__day-names {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
}

.react-datepicker__day-name {
    width: 2rem;
    height: 2rem;
    text-transform: capitalize;
    font-weight: normal;
    letter-spacing: 0.015em;
    color: #010203;
    margin: 0;
}

.react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 2rem;
    letter-spacing: 0.015em;
    width: 1.75rem !important;
    height: 1.75rem !important;
}

.react-datepicker__month {
    margin: 0;
    padding: 0 20px 30px 20px
}

.react-datepicker__week {
    display: flex;
    justify-content: space-between;
    width: 280px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, .react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #5077FF !important;
    color: #FFFFFF !important;
    opacity: 0.9 !important;
    border-radius: 0.3rem !important; 
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, 
.react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, 
.react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, 
.react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, 
.react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, 
.react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #5077FF !important;
    color: #FFFFFF !important;
    opacity: 0.9 !important;
    border-radius: 0.3rem !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: transparent !important;
    color: #5077FF !important;
    opacity: 0.9 !important;
}

.react-datepicker__navigation {
    top: 30px !important;
    border: none !important;
}

.react-datepicker__navigation--next {
    right: 20px !important;
}

.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow, 
.react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__navigation-icon::before {
    content: '';
    width: 20px !important;
    height: 20px !important;
    border-color: transparent !important;
    background-size: contain;
    background-repeat: no-repeat;
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%) rotate(0) !important;
}

.react-datepicker__navigation-icon--next::before {
    background-image: url('../../assets/images/date-next.png');
}

.react-datepicker__navigation--previous {
    left: 20px !important;
}

.react-datepicker__navigation-icon--previous::before {
    background-image: url('../../assets//images/date-prev.png');
}

