@font-face {
    font-family: 'Gilroy';
    font-weight: 300;
    src: local('Gilroy'), url('../assets/fonts/Gilroy-Light.woff2') format('woff2'), 
                          url('../assets/fonts/Gilroy-Light.woff') format('woff'),
                          url('../assets/fonts/Gilroy-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 400;
    src: local('Gilroy'), url('../assets/fonts/Gilroy-Regular.woff2') format('woff2'), 
                          url('../assets/fonts/Gilroy-Regular.woff') format('woff'),
                          url('../assets/fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 500;
    src: local('Gilroy'), url('../assets/fonts/Gilroy-Medium.woff2') format('woff2'), 
                          url('../assets/fonts/Gilroy-Medium.woff') format('woff'),
                          url('../assets/fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 600;
    src: local('Gilroy'), url('../assets/fonts/Gilroy-Semibold.woff2') format('woff2'), 
                          url('../assets/fonts/Gilroy-Semibold.woff') format('woff'),
                          url('../assets/fonts/Gilroy-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 700;
    src: local('Gilroy'), url('../assets/fonts/Gilroy-Bold.woff2') format('woff2'), 
                          url('../assets/fonts/Gilroy-Bold.woff') format('woff'),
                          url('../assets/fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 900;
    src: local('Gilroy'), url('../assets/fonts/Gilroy-Black.woff2') format('woff2'), 
                          url('../assets/fonts/Gilroy-Black.woff') format('woff'),
                          url('../assets/fonts/Gilroy-Black.ttf') format('truetype');
}