.wrapper {
    position:  relative;
    width: 100%;
}

.block {
    width: 59%;
    label {
        align-items: flex-start;
    }
    legend {
        font-weight: 600;
        color: #010203;
        span {
        font-weight: normal;
        display: block;
        padding-top: 10px;
        }
    }

    .form__block + .form__block {
        margin-top: 30px;
    }
    @media (max-width: 820px) {
        width: 100%;
    }
}

.form__block  + .form__block {
    margin-left: 30px;
    margin-top: 0;
}


.label {
    display: flex;
    align-items: center;
    cursor: pointer;
}
  
.label__block {
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 2px solid #EEEDF0;
    margin-right: 15px;
    @media (max-width: 414px) {
        margin-right: 10px;
    }
}
  
.label__block::after {
    content: "";
    visibility: hidden;
    background-image: url('../../assets/images/check.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: showBlock 0.3s ease;
}
  
.checkbox:checked + .label .label__block::after {
    visibility: visible;
}
  
.checkbox:checked + .label .label__block {
    border: 2px solid #019EF7;
    background: #019EF7;
    transition: all 0.3s ease;
}
  
.checkbox {
    position: absolute;
    width: 0.01px;
    height: 0.01px;
    top: 0;
    left: 0;
    visibility: hidden;
}
  
.legend {
    font-family: 'Gilroy';
    font-style: normal;
    letter-spacing: 0.04em;
    color: #767783;
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    @media (max-width: 1366px) {
        font-weight: 700;
        font-size: 16px;

    }
    @media (max-width: 414px) {
        font-size: 12px;
    }
    a {
        color: #5077FF;
        text-decoration: underline;
    }
    @media (max-width: 414px) {
        font-size: 12px;
    }
}