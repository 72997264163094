.burger {
    width: 17px;
    height: 15px;
    position:absolute;
    flex-shrink: 0;
}

.close {
  @media (max-width: 576px) {
      position: absolute;
  }
}

.close__item {
  @media (max-width: 576px) {
      position: absolute;
      height: 20px;
      width: 1.5px;
      top: 50%;
      left: 50%;
      background: #070D3F;
      &:first-child {
          transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:last-child {
          transform: translate(-50%, -50%) rotate(45deg);
      }
  }
}