@import '../../styles/vars';

.main {
    padding: 0;
    // background-image: url('../assets/images/bg.jpg');
    flex-grow: 1;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center 90%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1366px) {

    }
}