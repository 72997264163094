@import '../../styles/vars';

.main {
    // display: flex;
    // flex-direction: column;
    padding: 10vh 0;
    background-image: url('../../assets/images/bg.jpg');
    flex-grow: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 90%;
    @media (max-width: 1366px) {

    }
}

.logo {
    margin: 0 auto;
    display: block;
    height: 70px;
    width: 70px;
    border-radius: 15px;
    @media (max-width: 376px) {
        height: 60px;
        width: 60px;
        border-radius: 10px;
    }
  }

