@import '../../../styles/vars';

.panel__wrapper {
    padding: 35px 40px;
    background: #019EF7;
    border-radius: 6px;
    transform: translateY(-50%);
    @media (max-width: 1024px) {
        padding: 30px 30px 10px;
    }
    @media (max-width: 414px) {
        transform: translateY(-25%);
    }
}

.blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
}

.block {
    width: 22%;
    color: #FFFFFF;
    height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1024px) {
        width: 47.5%;
        height: 56px;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        &:nth-child(4n+2) {
            .block__logo {
                align-self: start;
            }
        }
    }
    @media (max-width: 414px) {
        width: 100%;
        height: 50px;
        margin-bottom: 25px;
    }
}

.block__top {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    @media (max-width: 768px) {
        padding-bottom: 0;
    }
}

.block__logo {
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
}

.block__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.015em;
}

.block__status {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.015em;
    display: flex;
    align-items: center;
    svg {
        display: block;
        margin-left: 12px;
        path {
        stroke: #FFFFFF;
        }
    }

    @media (max-width: 1200px) {
        font-size: 26px;
    }
    @media (max-width: 768px) {
        font-size: 24px;
    }
    @media (max-width: 480px) {
        font-size: 22px;
    }
}

.empty{
    background: #EDF1F6;
    border-radius: 6px;
    padding: 35px 40px;
    margin-top: 50px;
    color: #010203;
    @media (max-width: 1024px) {
        margin-top: 30px;
        padding: 30px;
    }
    @media (max-width: 480px) {
        padding: 30px 25px;
    }
    @media (max-width: 414px) {
        padding: 20px;
    }
}

.empty__descr {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.015em;
}

.empty__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.015em;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
        font-size: 1.625rem;
        margin-bottom: 15px;
    }
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    @media (max-width: 480px) {
        font-size: 1.375rem;
    }
}

.projects {
    @media (max-width: 1024px) {
        margin-top: -40px;
    }
    @media (max-width: 576px) {
        margin-top: -60px;
    }
}

.projects__unpublished {
    margin-bottom: 30px;
    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
}

.projects__top  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.projects__left {
    @media (max-width: 1024px) {
        width: 55%;
    }
    @media (max-width: 768px) {
        padding-bottom: 20px;
        width: 100%;
    }
}

.projects__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #010203;
    padding-bottom: 15px;
    @media (max-width: 480px) {
        line-height: 100%;
    }
}

.projects__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #00AB30;
    border-radius: 6px;
    padding: 20px 40px 20px 35px;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    cursor: pointer;
    transition: $anim;
    svg {
        display: block;
        margin-top: 2px;
        margin-left: 20px;
    }
    @media (max-width: 1024px) {
        padding: 16px 30px 15px 25px;
    }
    @media (max-width: 376px) {
        width: 100%;
    }
}
