@import '../../styles/vars';

.nav {
    background: #EDF1F6;
  }
  
.nav__wrapper {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
        display: flex;
        justify-content: space-between;
    }
}
  
.nav__item {
    font-weight: 700;
    padding: 20px 40px;
    border-bottom: 5px solid transparent;
    cursor: pointer;
    transition: $anim;
    color: #000000;
    @media (max-width: 576px) {
        padding: 20px 0;
        display: flex;
        justify-content: center;
        width: 33%;
    }
    @media (max-width: 414px) {
        font-size: 12px;
    }
}
  
.active {
    color: #000000;
    background: #FFFFFF;
    border-bottom: 5px solid #5077FF;
}