.wrapper {
    padding: 11px 16px;
    height: 170px;
    color: #FFFFFF;
    @media (max-width: 768px) {
        height: 160px;
    }
}

.main {
   background-image: url('../../assets/images/card__bg.jpg'); 
   background-position: center bottom;
   background-size: cover;
   background-repeat: no-repeat;
   color: #010203;
   .status {
        background: #00AB30;
        color: #FFFFFF;
   }
   .opened {
        color: #000E31;
   }
   .balance {
        color: #010203;
   }
}

.personal {
    background: #019EF7;
    .status {
        background: #F5F6FA;
        color: #010203;
   }
}

.project {
    background: #5077FF;
    .status {
        background: #F5F6FA;
        color: #010203;
   }
}

.status {
    padding: 9px 12px;
    color: #010203;
    border-radius: 30px;
    width: fit-content;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 100%;
    letter-spacing: 0.02em;
}

.card__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    @media (max-width: 768px) {
        margin-bottom: 8px 0;
    }
}

.title {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 100%;
    letter-spacing: 0.02em;
    max-width: 60%;
}

.settings {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #010203;
    background: #FFFFFF;
    @media (max-width: 768px) {
        span {
            display: none;
        }
    }
    svg {
        margin-left: 10px;
        @media (max-width: 768px) {
            margin-left: 0;
        }
    }
}

.opened {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0,9375rem;
    color: #FFFFFF;
    margin-bottom: 11px;
    @media (max-width: 768px) {
        margin-bottom: 8px;
    }
}

.balance {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2.5rem;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    @media (max-width: 768px) {
        line-height: 1.75rem;
    }
}