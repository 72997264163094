.info {
    // background: #EEEDF0;
    width: 100%;
    position: relative;
    .row__column {
        padding: 28px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.04em;
        color: #010203;
    }
    @media (max-width: 820px) {
        padding: 0px 20px;
        .row__column {
            padding: 0;
        }
    }
    @media (max-width: 576px) {
        padding: 0px 10px;
    }
    @media (max-width: 480px) {
        .row__column {
            font-size: 12px;
        }
    }
    @media (max-width: 376px) {
        .row__column {
            font-size: 10px;
        }
    }
}

.left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    .row__column {
        width: 24%;
        text-align: center;
    }
    @media (max-width: 1024px) {
        width: 60%;
    }
    @media (max-width: 820px) {
        width: 100%;
        padding: 20px 0;
        text-align: left;
        .row__column {
            text-align: left;
        }
    }
    @media (max-width: 576px) {
        .row__column {
            width: 29%;
        }
        .row__column:first-child,
        .row__column:last-child {
            width: 19%;
        }
    }
    @media (max-width: 480px) {
        padding: 15px 0;
    }
}

.right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    .right__column,
    .row__column {
        width: 50%;
        text-align: center;
    }
    @media (max-width: 1024px) {
        width: 40%;
    }
    @media (max-width: 820px) {
        display: none;
    }
}

.info-column {
    display: none;
    @media (max-width: 820px) {
        display: flex;
        flex-direction: column;
    }
}

.right__column {
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #EEEDF0;
    @media (max-width: 820px) {
        text-align-last: left;
    }    
}

.row {
    padding: 0px 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    transition: padding 0.3s ease;
    cursor: pointer;

    @media (max-width: 1024px) {
        padding: 0 15px;
    }
    @media (max-width: 820px) {
        flex-direction: column;
        width: 95%;
        padding: 0;
        .right {
            display: none;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
    @media (max-width: 576px) {
        width: 100%;
    }
}

.id {
    padding: 0px 20px !important;
    p {
        background: #020211;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #FFFFFF;
    }
    @media (max-width: 1024px) {
        padding: 0 10px !important;
    }
    @media (max-width: 820px) {
        padding: 0 !important;
        p {
            width: 50%;
        }
    }
    @media (max-width: 576px) {
        p {
            width: 80%;
        }
    }
    @media (max-width: 480px) {
        p {
            font-size: 10px;
        }
    }
    @media (max-width: 376px) {
        p {
            font-size: 8px;
            width: 90%;
        }
    }
}

.row__type {
    display: inline-block;
    padding-right: 20px;
}

.column__type {
    display: none;
    text-align: left;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #767783;
    width: 50%;
    @media (max-width: 480px) {
        font-size: 10px;
    }
    @media (max-width: 376px) {
        font-size: 8px;
    }
}

.column__info {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #010203;
    @media (max-width: 480px) {
        font-size: 12px;
    }
    @media (max-width: 376px) {
        font-size: 10px;
    }
    @media (max-width: 376px) {
        font-size: 8px;
    }
}

.menu {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -40px;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 820px) {
        display: block;
    }
    @media (max-width: 576px) {
        right: -15px;
    }
    @media (max-width: 414px) {
        right: -5px;
    }
}

.menu__line {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 2px;
    background: #d0d6dd;
    transition: 0.3s ease;
    &:first-child {
        top: 35%;
        transform: translate(-50%, 0);
    }
    &:last-child {
        top: 58%;
        transform: translate(-50%, 0);
    }
}

.row__active {
    .right {
        display: flex;
        padding: 20px 0;
    }
    .column__info {
        display: inline-block;
        width: 50%;
        text-align: left;
    }

    .menu__line {
        display: none;
        background: #8290A0;
        &:first-child {
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:last-child {
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    @media (max-width: 820px) {
        .right .column__type {
            display: inline-block;
        }
        .right {
            .row__column {
                display: flex;
                align-items: center;
                padding:0;
                width: 100%;
            }
            .row__column + .row__column {
                margin-top: 15px;
            }
        } 
    }
    @media (max-width: 480px) {
        .right {
            padding: 15px 0;
            .row__column + .row__column {
                margin-top: 10px;
            }
        }
    }
    @media (max-width: 376px) {
        .right {
            padding: 10px 0;
            .row__column + .row__column {
                margin-top: 8px;
            }
        }
    }
}