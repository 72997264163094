@import '../../styles/vars.scss';

.footer__wrapper {
    display: flex;
}

.footer__top {
    background: #F8F8FA;
    padding: 60px 0px 70px;
    @media (max-width: 576px) {
        padding: 60px 0px;
    }
    .footer__wrapper {
      display: flex;
      @media (max-width: 576px) {
        flex-wrap: wrap;
      }
    }
}
  
.column {
    &:first-child {
        width: 70%;
    }
    &:nth-child(3n+2) {
        width: 15%;
    }
    &:last-child {
        width: 15%;
    }
    @media (max-width: 1200px) {
        &:first-child {
            width: 60%;
        }
        &:nth-child(3n+2) {
            width: 20%;
        }
        &:last-child {
            width: 20%;
        }
    }
    @media (max-width: 820px) {
        &:first-child {
            width: 50%;
        }
        &:nth-child(3n+2) {
            width: 25%;
        }
        &:last-child {
            width: 25%;
        }
    }
    @media (max-width: 576px) {
        &:first-child {
            width: 100%;
            padding-bottom: 30px;
        }
        &:nth-child(3n+2) {
            width: 47.5%;
        }
        &:last-child {
            width: 47.5%;
        }
    }
}


.column__text {
    max-width: 602px;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #020211;
    padding-bottom: 40px;
    @media (max-width: 1200px) {
        max-width: 445px;
        font-size: 18px;
    }
    @media (max-width: 820px) {
        font-size: 16px;
    }
    @media (max-width: 576px) {
        font-size: 18px;
        max-width: 100%;
    }
    @media (max-width: 414px) {
        font-size: 16px;
    }
}

.footer__links {
    display: flex;
    align-items: center;
}

.footer__link {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #070D3F;
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.footer__link + .footer__link {
    margin-left: 12px;
}

.nav__link {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: right;
    color: #010203;
    @media (max-width: 576px) {
        text-align-last: left;
    }
}

.nav__link + .nav__link {
    margin-top: 15px;
}

.footer__contact {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: right;
    color: #010203;
    @media (max-width: 576px) {
        text-align-last: left;
    }
}

.footer__contact + .footer__contact {
    margin-top: 15px;
}

.footer__bottom {
    background: #FFFFFF;
    padding: 20px 0;
    .footer__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 820px) {
            flex-direction: column;
        }
    }
}

.footer__policy {
    display: flex;
    align-items: center;
    @media (max-width: 820px) {
        justify-content: space-between;
        padding-bottom: 15px;
        width: 100%;
    }
    @media (max-width: 576px) {
        flex-wrap: wrap;
        justify-content: space-around;
        p {
            width: 100%;
            text-align: center;
            padding-bottom: 15px;
        }
        a {
            width: 47.5%;
        }
    }
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        p {
            text-align: left;
        }
        a {
            width: 100%;
        }
        a + a {
            margin-top: 8px;
        }
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #767783;
        padding-right: 35px;
    }
}

.footer__rules {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #010203;
}

.footer__rules + .footer__rules {
    margin-left: 20px;
    @media (max-width: 820px) {
        margin-left: 0;
    }
}

.footer__langs {
    display: flex;
}

.footer__lang {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #010203;
    padding: 0px 15px;
    color: #767783;
    cursor: pointer;
    transition: $anim;
    &:first-child {
        position: relative;
        &::after {
        content: '';
        height: 11px;
        width: 1px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        background: #767783;
        }
    }
}

.active {
    font-weight: 600;
    color: #010203;
}