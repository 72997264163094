.settings__wrapper {
    background: transparent;
    position: relative;
}

.block {
    width: 59%;
    @media (max-width: 820px) {
        width: 100%;
    }
}

.block__wrapper {
    max-width: 500px;
}

.block__img {
    width: 80px;
    height: 80px;
    background: #EDF1F6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.06em;
    color: #5077FF;
    margin-bottom: 20px;
}

.block__main {
    button {
        color: #010203;
        border: 1px solid #EEEDF0;
        background: transparent;
        &:hover {
            border: 1px solid #010203;
        }
    }
}

.block {
    label {
        align-items: flex-start;
    }
    legend {
        font-weight: 600;
        color: #010203;
        span {
        font-weight: normal;
        display: block;
        padding-top: 10px;
        }
    }

    .form__block + .form__block {
        margin-top: 30px;
    }
}

.radio {
    display: flex;
    align-items: center;
    padding: 20px 0;
    max-width: 330px;
}

.radio {
    @media (max-width: 414px) {
        margin-bottom: 20px;
    }
}

.block__wrapper {
    position: relative;
    margin-bottom: 30px;
  }

.block__descr {
    line-height: 150%;
    max-width: 590px;
    @media (max-width: 1024px) {
        max-width: 100%;
    }
    @media (max-width: 414px) {
        font-size: 12px;
    }
}

.form__radio {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 1366px) {
        margin-bottom: 25px;
    }
    @media (max-width: 414px) {
        margin-bottom: 20px;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    border-radius: 15px;
    height: 240px;
    background-image: url(../../assets/images/card__bg.jpg);
    background-size: cover;
    background-position: center center;
    @media (max-width: 480px) {
        padding: 30px 25px;
    }
    @media (max-width: 414px) {
        padding: 20px;
    }
    @media (max-width: 376px) {
        height: 200px;
    }
}

.card__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    @media (max-width: 820px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media (max-width: 640px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.card__number {
    font-weight: 500;
    font-size: 22px;
    @media (max-width: 1024px) {
        margin-bottom: 15px;
    }
    @media (max-width: 820px) {
        margin-bottom: 0;
    }
    @media (max-width: 640px) {
        margin-bottom: 15px;
    }

    @media (max-width: 376px) {
        font-size: 20px;
    }
}

.card__balance {
    font-weight: 700;
    font-size: 60.8px;
    line-height: 100%;
    @media (max-width: 1366px) {
        font-size: 50px;
    }
    @media (max-width: 1200px) {
        font-size: 42px;
    }
    @media (max-width: 376px) {
        font-size: 36px;
    }
}

.card__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    @media (max-width: 820px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media (max-width: 640px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.card__date {
    span {
        font-weight: 700;
    }
    @media (max-width: 1024px) {
        margin-bottom: 15px;
    }
    @media (max-width: 820px) {
        margin-bottom: 0;
    }
    @media (max-width: 640px) {
        margin-bottom: 15px;
    }
}
.card__btns {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1024px) {
        margin-left: auto;
    }
}

.card__btn {
    color: #FFFFFF !important;
    &:first-child {
        background: #EB001B;
        margin-right: 20px;
        border: 2px solid #EB001B !important;
        @media (max-width: 1200px) {
            margin-right: 15px;
        }
        &:hover {
            background: #FFFFFF;
            color: #EB001B !important;
        }
    }
    &:last-child {
        background: #019EF7;
        border: 2px solid #019EF7 !important;
        &:hover {
            background: #FFFFFF;
            color: #019EF7 !important;
        }
    }
}
