.skeleton__card {
    width: 100%;
    position: relative;
    background: rgba(195, 218, 227, 0.2);
    border-radius: 0.25rem;
}
.shimmer__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
 }
 .shimmer {
    width: 50%;
    height: 100%;
    opacity: 0.2;
    background: rgba(238, 238, 238, 0.966);
    transform: skewX(-20deg);
    animation: loader 1s linear infinite;
 }
 @keyframes loader {
   0% {
    transform: translateX(-150%);
   }
   50% {
    transform: translateX(-60%);
   }
   100% {
    transform: translateX(150%);
   }
 }

 .image {
    background: rgba(195, 218, 227, 0.2);
    position: relative;
    width: 140px;
    height: 80px;
    margin-bottom: 20px;
    border-radius: 0.25rem;
}

.block__main {
    button {
        color: #010203;
        border: 1px solid #EEEDF0;
        background: transparent;
        &:hover {
            border: 1px solid #010203;
        }
    }
}

.block__wrapper {
    position: relative;
    max-width: 500px;
}

.block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 59%;
    min-height: 535px;
    @media (max-width: 820px) {
        width: 100%;
    }

    .form__block + .form__block {
        margin-top: 30px;
        @media (max-width: 576px) {
            margin-top: 20px;
        }
    }
}

.title {
    background: rgba(195, 218, 227, 0.2);
    position: relative;
    width: 40%;
    height: 1.25rem;
    margin-bottom: 30px;
    border-radius: 0.25rem;
}

.block__descr {
    position: relative;
    height: 3rem;
    background: rgba(195, 218, 227, 0.2);
    position: relative;
    max-width: 500px;
    border-radius: 0.25rem;
}

.project__block {
    background: rgba(195, 218, 227, 0.2);
    width: 100%;
    position: relative;
    height: 12.5rem;
    border-radius: 15px;
    margin-top: 30px;
}

.bottom {
    height: 75px;
}