.loader__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(214, 224, 231, 0.8);
  z-index: 10;
}
// .loader__wrapper {
//   flex-grow: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// }

.loader{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  margin: 20px;
  display:inline-block;
}
// h1{
//   text-align: center;
//   color: $fontColor;
//   margin: 0 0 100px;
//   font-size: 34px;
//   font-weight: 100;
//   text-transform: uppercase;
//   background-color: darken($baseColor, 5);
//   padding: 20px 0;
//   b{
//     font-weight: 700;
//   }
// }

//follow me template