@import '../../styles/vars';

.form {
    padding: 40px;
    background: #FFFFFF;
    border-radius: 15px;
    margin-bottom: 30px;
    @media (max-width: 576px) {
        padding: 30px;
    }
    @media (max-width: 414px) {
        padding: 25px;
    }
    @media (max-width: 376px) {
        padding: 20px;
        border-radius: 10px;
    }
}

.block {
    position: relative;
}

.block__wrapper {
    position: relative;
    margin-bottom: 30px;
    @media (max-width: 1366px) {
        // margin-bottom: 25px;
    }
    @media (max-width: 414px) {
        // margin-bottom: 15px;
    }
}

.block__show {
    position: absolute;
    display: block;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: $anim;
    cursor: pointer;
}

.block__show:hover {
    fill: #314BCE;
    path {
        fill: #314BCE;
    }
}

input:hover + .block__show {
    fill: #314BCE;
    path {
        fill: #314BCE;
    }
}

.block__show:hover + .block__inp {
    background: rgba(49, 75, 206, 0.08);
    border: 1px solid #314BCE;
    color: #314BCE;
    &::placeholder {
        color: #314BCE;
    }
}

.form__submit {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
    position: relative;
    p {
        bottom: 65px;
    }
    .block__subt {
        margin-bottom: 0;
        font-weight: 600;
        margin-right: 50px;
        text-decoration-line: underline;
        transition: $anim;
        &:hover {
        color: #5077FF;
        }
    }
    button {
        margin-top: 30px;
    }
    label {
        justify-content: flex-end;
        flex-grow: 1;
        flex-shrink: 0;

    }
    legend {
        font-weight: 600;
    }
    @media (max-width: 1366px) {
        button {
            margin-top: 25px;
        }
    }
    @media (max-width: 480px) {
        a {
            display: block;
        }
    }
}

.main__logo {
    margin: 0 auto;
    display: block;
    height: 70px;
    width: 70px;
    border-radius: 15px;
}

.block__descr {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.04em;
    margin-bottom: 30px;
    width: 80%; 
    @media (max-width: 1366px) {
        margin-bottom: 25px;
    }
    @media (max-width: 480px) {
        width: 90%;
    }
    @media (max-width: 414px) {
        margin-bottom: 20px;
        font-size: 10px;
        width: 95%;
    }
}

.recovery__btn {
    margin-left: auto;
}

.block__show:hover + input {
    background: rgba(49, 75, 206, 0.08);
    border: 1px solid #314BCE;
    color: #314BCE;
    &::placeholder {
        color: #314BCE;
    }
}

.success {
    background: #00AB30;
    border: 2px solid #00AB30;
    width: 200px;
    margin-left: auto;
}