@import './../../styles/vars';

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    height: 120px;

    box-shadow: 
                0px 4px 22px rgba(0, 0, 0, 0.07), 
                0px 0.893452px 4.91399px rgba(0, 0, 0, 0.0417275), 
                0px 0.266004px 1.46302px rgba(0, 0, 0, 0.0282725);
    color: #FFFFFF;
    cursor: pointer;
    position: relative;
    transition: $anim;
    &__attension {
        background: #EB001B;
        &:hover {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #EB001B;
            opacity: 0.75;
            z-index: 2;
        }
        }
    }
    &__active {
        background: #00AB30;
        &:hover {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #00AB30;
            opacity: 0.75;
            z-index: 2;
        }
        }
    }
    &__closed {
        background: #282828;
        &:hover {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #282828;
            opacity: 0.75;
            z-index: 2;
        }
        }
    }
    &:hover {
        z-index: 1;
        background-image: url('../../assets/images/cubist_houses.jpg');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.card__top {
    position: relative;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 5;
    @media (max-width: 576px) {
        line-height: 100%;
        padding-bottom: 10px;
    }
}

.card__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.015em;
    @media (max-width: 576px) {
        line-height: 100%;
        padding-bottom: 10px;
    }
}

.card__subt {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.015em;
}

.card__sum {
    position: relative;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.2px;
    z-index: 5;
    @media (max-width: 1200px) {
        font-size: 18px;
        line-height: 18px;
    }
}