.block {
    background: #F8F8FA;
    border-radius: 15px;
    padding: 30px;
    margin-top: 30px;
    @media (max-width: 576px) {
        margin-top: 20px;
    }
    @media (max-width: 480px) {
        padding: 25px;
    }
    @media (max-width: 414px) {
        padding: 20px;
    }
}

.type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 25px;
        @media (max-width: 576px) {
            margin-bottom: 20px;
        }
    }
}

.name {
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.015em;
    color: #000000;
    @media (max-width: 576px) {
        line-height: 26px;
    }
    @media (max-width: 376px) {
        width: 30%;
        line-height: 20px;
    }
}

.ammount {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.015em;
    color: #010203;
    @media (max-width: 576px) {
        font-size: 26px;
    }
    @media (max-width: 376px) {
        font-size: 20px;
    }
}

.calc {
   p {color: #AFB0B8;}
}