@import '../../styles//vars';

.table {
    padding-bottom: 60px;
}

.table__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 20px;
    @media (max-width: 768px) {
       padding: 0 15px 15px; 
    }
    @media (max-width: 576px) {
        padding: 0 0 15px; 
     }
}

.table__right {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 576px) {
        padding-left: 60px;
    }
    @media (max-width: 576px) {
        padding-left: 50px;
        svg {
            width: 18px;
            height: 12px;
        }
    }
}

.table__calendar {
    padding: 10px 20px;
    border: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    span {
        line-height: 1.5rem;
        margin-right: 30px;
        color: #333333;
    }
    @media (max-width: 768px) {
        span {
            margin-right: 20px;
        }
    }
    @media (max-width: 576px) {
        padding: 8px 15px;
        margin-left: auto;
        span {
            line-height: 1.25rem;
        }
        svg {
            width: 20px;
            height: 20px;
        }
    }
    // @media (max-width: 414px) {
    //     padding: 6px 10px;
    //     svg {
    //         width: 18px;
    //         height: 18px;
    //     }
    // }
}

.header__search{
    margin-right: 30px;
    position: relative;
    width: 250px;
    height: 40px;
    z-index: 10;
    transition: all 500ms cubic-bezier(.21,.8,.37,.97);
    @media (max-width: 768px) {
        // width: 220px;
        margin-right: 20px;
    }
    @media (max-width: 576px) {
        margin-right: 0;
        top: 0;
        left: 0;
        position: absolute;
        width: 40px;
    }
  }
  
.search__open {
    width: 100%;
}
  
.search__inp {
    height: 40px;
    width: 250px;
    border-style: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    outline: none;
    border-bottom: 1px solid transparent;
    transition: all .5s ease-in-out;
    color:#767783;
    @media (max-width: 768px) {
        font-size: 12px;
        &::placeholder {
            font-size: 12px;
        }
    }
    @media (max-width: 576px) {
        width: 40px;
        padding-right: 40px;
    }
}
    
.search__inp::placeholder{
    color:#767783;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
}

.search__btn{
    height: 40px;
    border-style: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
    background-color:transparent;
    pointer-events: painted;  
    svg {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #767783;
        transition: $anim;
    }
    &:hover {
        svg {
        fill: #5077FF;
        transform: translate(-50%, -50%) scale(1.1);
        }
    }
    @media (max-width: 576px) {
    padding: 20px;
        width: 40px;
        svg {
            width: 13px;
            height: 13px;
        }
    }
}

.search__open .search__inp {
    width: 100%;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E5E5E5;
    transition: all 500ms cubic-bezier(.21,.8,.37,.97);
}