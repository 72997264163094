.modal__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(214, 224, 231, 0.8);
    z-index: 10;
}

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    padding: 40px 30px 40px 20px;
    width: 376px;
    border-radius: 15px;
    overflow: hidden;
    @media (max-width: 576px) {
       width: 75vw;
       padding: 30px 20px;
    }
    @media (max-width: 480px) {
        width: 80vw;
    }
    @media (max-width: 480px) {
        width: 90vw;
        padding: 20px;
    }
}

.modal__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        font-weight: 400;
        font-size: 0,875rem;
        line-height: 100%;
        letter-spacing: 0.04em;
        color: #767783;
        padding-bottom: 20px;
    }
    h3 {
        font-weight: 500;
        font-size: 1,25rem;
        line-height: 100%;
        letter-spacing: 0.015em;
        color: #010203;
        padding-bottom: 20px;
    }
    span {
        font-weight: 400;
        font-size: 0,875rem;
        line-height: 100%;
        letter-spacing: 0.04em;
        text-decoration-line: underline;
        color: #767783;
        cursor: pointer;
        transition: all 0.3s ease;
    }
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        width: 70%;
    }
    a {
        margin-left: auto;
    }
    button {
        margin-left: auto;
    }

}

.close {
    position: absolute;
    top: 25px;
    right: 25px;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    @media (max-width: 576px) {
        top: 20px;
        right: 20px;
    }
    @media (max-width: 576px) {
        top: 15px;
        right: 15px;
     }
}