@import '../../styles/vars';

.empty{
    background: #EDF1F6;
    border-radius: 6px;
    padding: 35px 40px;
    margin-top: 50px;
    color: #010203;
    @media (max-width: 1024px) {
        margin-top: 30px;
        padding: 30px;
    }
    @media (max-width: 480px) {
        padding: 30px 25px;
    }
    @media (max-width: 414px) {
        padding: 20px;
    }
}

.empty__descr {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.015em;
}

.empty__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.015em;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
        font-size: 1.625rem;
        margin-bottom: 15px;
    }
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    @media (max-width: 480px) {
        font-size: 1.375rem;
    }
}

.accounts {
    margin-bottom: 30px;
    @media (max-width: 1024px) {
        margin-top: -40px;
    }
    @media (max-width: 768px) {
        margin-bottom: 0px;
    }
    @media (max-width: 576px) {
        margin-top: -60px;
    }
    @media (max-width: 414px) {
        margin-top: -40px;
    }
}

.accounts__top  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.accounts__left {
    width: 25%;
    @media (max-width: 1200px) {
        width: 100%;
        margin-bottom: 30px;
    }
    @media (max-width: 480px) {
        margin-bottom: 20px;
    }
}

.accounts__right {
    display: flex;
    a + a {
        margin-left: 27px;
    }
    @media (max-width: 820px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 20px;
        width: 100%;
        a {
            width: 100%;
        }
        a + a {
            margin-left: 0;
        }
    }
    @media (max-width: 576px) { 
        grid-column-gap: 20px;
    }
    @media (max-width: 480px) { 
        grid-template-columns: repeat(1, 1fr);
    }
    @media (max-width: 376px) { 
        a {
            max-width: 100%;
        }
    }
}

.accounts__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #010203;
    padding-bottom: 15px;
    @media (max-width: 480px) {
        line-height: 100%;
    }
}

.accounts__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #00AB30;
    border-radius: 6px;
    padding: 20px 40px 20px 35px;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    cursor: pointer;
    transition: $anim;
    svg {
        display: block;
        margin-top: 2px;
        margin-left: 20px;
    }
    @media (max-width: 1024px) {
        padding: 16px 30px 15px 25px;
    }
    @media (max-width: 576px) {
        padding: 16px 20px;
    }
    @media (max-width: 376px) {
        width: 100%;
    }
}

.accounts__list {
    padding: 45px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    @media (max-width: 1024px) {
        padding: 30px 0;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 30px;
    }
    @media (max-width: 576px) {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }
    @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
