.top {
    padding: 20px;
    background: #F8F8FA;
    @media (max-width: 1024px) {
        padding: 20px 15px;
    }
    @media (max-width: 820px) {
        padding: 20px;     
    }
    @media (max-width: 820px) {
        padding: 20px 10px;     
    }
}

.top__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 820px) {
        width: 95%;
    }
    @media (max-width: 576px) {
        width: 100%;
    }
}

.left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    position: relative;
    .top__column {
        width: 24%;
        text-align: center;
    }
    @media (max-width: 1024px) {
        width: 60%;
    }
    @media (max-width: 820px) {
        width: 100%;
        .top__column {
            text-align: left;
        }
    }
    @media (max-width: 576px) {
        .top__column {
            width: 29%;
        }
        .top__column:first-child,
        .top__column:last-child {
            width: 19%;
        }
    }
}

.right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    .row__column {
        width: 50%;
        text-align: center;
    }
    .right__column {
        width: 50%;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #767783;
    }
    @media (max-width: 1024px) {
        width: 40%;
    }
    @media (max-width: 820px) {
        display: none;
    }
}

.top__column {
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #767783;
    @media (max-width: 480px) {
        font-size: 10px;
    }
    @media (max-width: 376px) {
        font-size: 8px;
    }
}





